import React from 'react';
import {styled} from 'styled-components';
import {mediaQueries, typographyHeading2Styles} from '../modules/common/styles';
import useTranslation from 'next-translate/useTranslation';
import att from '../images/us-carriers/colors/att.png';
import boost from '../images/us-carriers/colors/boost.webp';
import cricket from '../images/us-carriers/colors/cricket.png';
import h2o from '../images/us-carriers/colors/h2o.webp';
import lyca from '../images/us-carriers/colors/lyca.webp';
import metro from '../images/us-carriers/colors/metro.png';
import simpleMobile from '../images/us-carriers/colors/simple-mobile.png';
import tMobile from '../images/us-carriers/colors/t-mobile.png';
import verizon from '../images/us-carriers/colors/verizon.png';
import Image from 'next/image';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 1128px;
    align-items: center;
    margin: 2rem auto 5rem;

    ${mediaQueries.lessThan('small')`
        margin: 0 auto 5rem;
        text-align: center;
        padding: 0 16px;
    `};
`;

const Title = styled.h2`
    ${typographyHeading2Styles}
    margin: 0px;
    color: ${({theme}) => theme.colors.ballena};

    ${mediaQueries.lessThan('small')`
        padding: unset;
        margin: 0 0 32px;
    `};
`;

const LogosContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    gap: 1rem;
    padding: 0 7.875rem;

    ${mediaQueries.lessThan('small')`
        padding: unset;
        margin: 0 16px;
    `};
`;

const Logo = styled(Image)`
    object-fit: contain;
    max-width: 160px;
    height: auto;
    max-height: 48px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);

    ${mediaQueries.lessThan('small')`
        max-height: 32px;
        max-width: 100px;
    `};
`;

const Link = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 120px;
    padding: 8px 16px;
    box-sizing: border-box;
    border-radius: 8px;
    border: solid 1px ${({theme}) => theme.colors.atun};

    ${mediaQueries.lessThan('small')`
        width: 100%;
        height: 60px;
        max-width: 156px;
        padding: 8px;
    `};
`;

export const AllUSCarriers = () => {
    const {t, lang} = useTranslation();
    const promoBaseURL =
        lang == 'en'
            ? process.env.NEXT_PUBLIC_PROMO_URL_EN
            : process.env.NEXT_PUBLIC_PROMO_URL_ES;

    const logos = [
        {
            logoSrc: tMobile,
            alt: 'T-Mobile',
            ackUrl: '/ret/t-mobile',
        },
        {
            logoSrc: att,
            alt: 'AT&T',
            ackUrl: '/ret/att',
        },
        {
            logoSrc: metro,
            alt: 'Metro',
            ackUrl: '/ret/metro-pcs',
        },
        {
            logoSrc: cricket,
            alt: 'Cricket',
            ackUrl: '/ret/cricket',
        },
        {
            logoSrc: simpleMobile,
            alt: 'Simple Mobile',
            ackUrl: '/ret/simple-mobile',
        },
        {
            logoSrc: verizon,
            alt: 'Verizon',
            ackUrl: '/ret/verizon',
        },
        {
            logoSrc: boost,
            alt: 'Boost Mobile',
            ackUrl: '/ret/boost-mobile',
        },
        {
            logoSrc: h2o,
            alt: 'H2O Wireless',
            ackUrl: '/ret/h2o-wireless',
        },
        {
            logoSrc: lyca,
            alt: 'Lyca Mobile',
            ackUrl: '/ret/lyca-mobile',
        },
    ];

    return (
        <Container>
            <Title id="all-carriers">
                {t('home_landing:us_carriers_supported')}
            </Title>
            <LogosContainer>
                {logos.map((logo, index) => (
                    <Link href={promoBaseURL + logo.ackUrl} key={index}>
                        <Logo key={index} src={logo.logoSrc} alt={logo.alt} />
                    </Link>
                ))}
            </LogosContainer>
        </Container>
    );
};
